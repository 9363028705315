// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
} from '@headlessui/vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
    },

    data() {
        return {

        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // A minimal responsive menu with just vanilla JS
        new MobileNav();
    },
}).mount('#app');
